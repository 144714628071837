import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ScrollService {
  public scrollPosition$: Subject<number> = new Subject();

  scroll(event) {
    this.scrollPosition$.next(event.srcElement.scrollTop);
  }
}
